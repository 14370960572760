import React, { useState, useEffect } from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from './styles/fairs.module.scss'

export default function Fairs() {

	const compare = (a, b) => {
    if (a.node.order > b.node.order) {
      return -1;
    } else {
      return 1;
    }
    return 0;
  }

  return (
    <Layout pageclass='fairs'>
      <Seo title="Fairs" />
      <StaticQuery
        query={graphql`
          query Fairs {
            allContentfulFairs {
							edges {
								node {
									slug
									order
									date
									isCurrent
									title
								}
							}
						}
          }
        `}
        render={result => {
          const data = result.allContentfulFairs.edges

          return (
            <div className={styles.root}>
							{
								data.filter(each => each.node.isCurrent).length ?
								<div className={styles.upcoming}>
									<div className={styles.title}>Upcoming</div>
									<div className={styles.links}>
									  {data.filter(each => each.node.isCurrent).sort(compare).map((fair, index) => <FairTitle key={index} data={fair}/>)}
									</div>
								</div> :
								null
							}

							<div className={styles.past}>
							  <div className={styles.title}>Past</div>
								<div className={styles.links}>
								  {data.filter(each => !each.node.isCurrent).sort(compare).map((fair, index) => <FairTitle key={index} data={fair}/>)}
								</div>
							</div>
            </div>
          )
        }}
      />
    </Layout>
  )
}

const FairTitle = ({data}) => {
  const [isImageVisible, setIsImageVisible] = useState(false)

  return (
    <div className={styles.fairTitle}>
			<div className={styles.linkWrapper}>
				<Link
					className={styles.link}
					to={data.node.slug}
					onMouseEnter={() => setIsImageVisible(true)}
					onMouseLeave={() => setIsImageVisible(false)}
				>
					<div className={styles.fairTitle}>{data.node.title}</div>
					<div className={styles.fairDate}>{data.node.date}</div>
				</Link>
			</div>
			{
				data.node.featuredImage ?
				<div className={`${styles.imageContainer} grid-parent`}>
					<img className={`${styles.featuredImage} ${isImageVisible ? styles.isVisible : null}`} src={data.node.featuredImage.file.url} />
				</div>
				: null
			}
    </div>
  )
}
