// extracted by mini-css-extract-plugin
export var fairDate = "fairs-module--fairDate--d5cd7";
export var fairTitle = "fairs-module--fairTitle--ef902";
export var featuredImage = "fairs-module--featuredImage--50cd5";
export var imageContainer = "fairs-module--imageContainer--03277";
export var isVisible = "fairs-module--isVisible--614b4";
export var link = "fairs-module--link--6976c";
export var linkWrapper = "fairs-module--linkWrapper--eec9e";
export var links = "fairs-module--links--30b1b";
export var past = "fairs-module--past--24b23";
export var root = "fairs-module--root--cf302";
export var title = "fairs-module--title--6941e";
export var upcoming = "fairs-module--upcoming--0ac8e";